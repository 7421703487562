import React from 'react';
import { StarIcon } from '@/assets/svgExports/StarIcon';
import { RatingContainer, RatingText } from './DisplayRating.styles';

function DisplayRating(props) {
  const { storeItemReviewRating } = props;
  if (!storeItemReviewRating?.ratings_count) return null;
  return (
    <RatingContainer>
      {!!storeItemReviewRating?.review_rating?.length && (
        <RatingText>
          <span className="ml8px">
            {storeItemReviewRating?.review_rating[0].avg_rating}
          </span>
          <span className="flex ml2px">
            <StarIcon />
          </span>
          <span className="cacaca ml6px mr6px">|</span>{' '}
          <span className="mr8px">{storeItemReviewRating?.ratings_count}</span>
        </RatingText>
      )}
    </RatingContainer>
  );
}

export default DisplayRating;
