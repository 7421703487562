import styled from 'styled-components';

export const ColoredRectangleElementContainer = styled.div`
  margin-right: 12px;
  margin-top: 8px;
  cursor: pointer;
`;

export const ColoredCircleElementContainer = styled.div`
  margin-right: 9px;
  margin-top: 8px;
  cursor: pointer;
`;

export const ColoredEllipseElementContainer = styled.div`
  margin-right: 4px;
`;
