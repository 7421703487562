export function ColorEllipse(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle
        cx="7.99957"
        cy="7.99957"
        r="6.22222"
        fill={props?.color ? props?.color : '#FFFFFF'}
      />
      <circle
        cx="8"
        cy="8"
        r="7.5"
        stroke={
          props?.isFirst
            ? props?.color === '#FFFFFF'
              ? '#000000'
              : props?.color
            : '#FFFFFF'
        }
      />
    </svg>
  );
}
