export function StarIcon() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0L6.21084 3.33343L9.75528 3.45492L6.95918 5.63657L7.93893 9.04508L5 7.06L2.06107 9.04508L3.04082 5.63657L0.244718 3.45492L3.78916 3.33343L5 0Z"
        fill="#359D29"
      />
    </svg>
  );
}
