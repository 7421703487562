import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlatList from '@/components/FlatList';
import { fetchCollectionCatalogItemById } from '../../../../../../redux/actions';
import AdvanceCard from '../../../../AdvanceCard/AdvanceCard';
import {
  CollectionCategoryNameText,
  CollectionCategoryWrapper,
  CollectionItemsContainer,
  CollectionNameText,
  CollectionWrapper,
} from './AllCollectionsItems.styles';
import { useSSRSelector } from '@/redux/ssrStore';

function AllCollectionsItems(props) {
  const dispatch = useDispatch();

  const { collections, storeData } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
    storeData: state.storeReducer.store,
  }));

  const { collectionCatalogItems } = useSelector((state) => ({
    collectionCatalogItems: state.catalogReducer.collectionCatalogItems,
  }));

  /**
   * this function fetches collection category items when data
   * needs to be loaded
   * @param {number} category
   * @param {number} collection
   */

  function loadMoreData(category, collection) {
    if (storeData?.store_id) {
      dispatch(
        fetchCollectionCatalogItemById({
          storeId: storeData.store_id,
          collectionId: collection.id,
          categoryId: category.id,
        })
      );
    }
  }

  /**
   * this function checks collection object for presence of category and its items
   * as it may or may not be there as we call api when we need to show the items
   * @param {number} collectionId
   * @param {number} categoryId
   * @returns {Array}  of items that needs to be rendered in a collection category
   */

  function returnNestedCollectionData(collectionId, categoryId) {
    const collectionIdValue = collectionCatalogItems[collectionId];
    let categoryIdValue = undefined;
    if (collectionIdValue) {
      categoryIdValue = collectionIdValue[categoryId];
    }
    return categoryIdValue;
  }

  return (
    <>
      {collections?.length
        ? collections.map((collection) => {
            return (
              <CollectionWrapper
                showSortByDropDown={props?.showSortByDropDown}
                showFilterDrawer={props?.showFilterDrawer}
                key={collection?.id}
              >
                {collection?.categories?.map((category) => {
                  const collectionData = returnNestedCollectionData(
                    collection.id,
                    category.id
                  );

                  return (
                    <CollectionCategoryWrapper key={category?.id}>
                      <CollectionCategoryNameText>
                        <CollectionNameText>{`${collection.name}  /  `}</CollectionNameText>
                        {category.name}
                      </CollectionCategoryNameText>
                      <CollectionItemsContainer>
                        <FlatList
                          isLoaderVisible={false}
                          LoaderComponent={null}
                          isNext={collectionData ? false : true}
                          onScrollEnd={() => loadMoreData(category, collection)}
                          // add LoaderComponent for loading sections
                          renderList={() => {
                            return collectionData?.map((data) => {
                              return (
                                <AdvanceCard
                                  key={data.id}
                                  data={data}
                                  showButton={true}
                                  noTags={!data?.tags?.length}
                                />
                              );
                            });
                          }}
                        />
                      </CollectionItemsContainer>
                    </CollectionCategoryWrapper>
                  );
                })}
              </CollectionWrapper>
            );
          })
        : null}
    </>
  );
}

export default AllCollectionsItems;
