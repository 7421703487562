import React, { useEffect, useState } from 'react';
import {
  AdvAmountActual,
  AdvAmountDiscounted,
  AdvCardBody,
  AdvCardColorComponent,
  AdvCardContainer,
  AdvCardDescription,
  AdvCardDetailContainer,
  AdvCardDetailLeftSection,
  AdvCardDetailRightSection,
  AdvCardDiscount,
  AdvCardInventory,
  AdvCardPrice,
  AdvCardTitle,
  AdvImage,
  AdvImageHover,
  AdvImageWrapper,
  AdvTagsContainer,
  AdvTagsItem,
} from './AdvanceCard.styles';
import { checkForLimitedQuantity } from '@/utils/checkForLimitedQuantity';
import { getStoreType } from '@/utils/getStoreType';
import { CURRENCY_SYMBOL } from '../../../config';
import { getDiscountPercentage } from '../../../utils/getDiscountPercentage';
import DisplayRating from '../MiddleBody/MiddleBody7/Components/DisplayRating/DisplayRating';
import ColorVariant from '../../ColorVariant/ColorVariant';
import { useItemClick } from '../../../hooks/useItemClick';
import {
  DEFAULT_IMAGE_URL,
  ITEM_ACTIVE_STATUS,
  STORE_CUSTOMIZATION,
} from '../../../utils/constants';
import {
  getImageUrlFromCompressedImages,
  imgSizeEnums,
} from '@/utils/compressedImagesHelpers';
import { Link } from '@/components/Link';
import { useSSRSelector } from '@/redux/ssrStore';
import OfferAvailableTag from 'src/components/CouponsAndVouchers/OfferAvailableTag';

function AdvanceCard(props) {
  const { data, noTags } = props;
  const [discountPercent, setDiscountPercent] = useState(0);
  const [itemColors, setItemColors] = useState(0);
  const [onItemClick, getLink] = useItemClick();
  const { theme, oosStatus, storeData } = useSSRSelector((state) => {
    const storeData = state.storeReducer.store;

    return {
      theme: storeData,
      oosStatus: storeData?.services?.hide_out_of_stock,
      storeData,
    };
  });
  const isB2bStore = getStoreType(storeData) === STORE_CUSTOMIZATION.B2B;

  useEffect(() => {
    setItemColors(getItemColors());
  }, [data?.item_option_variants]);

  useEffect(() => {
    if (data) {
      const percent = getDiscountPercentage(data?.price, data?.discounted_price);
      setDiscountPercent(percent ? percent : 0);
    }
  }, [data]);

  if (data?.is_active === ITEM_ACTIVE_STATUS.HIDDEN) return null;
  /**
   * @returns {*} array Containing color code associated with variants of that item
   */

  function getItemColors() {
    const tempItemColors = [];
    !!data?.item_option_variants &&
      !!data?.item_option_variants?.length &&
      data?.item_option_variants.forEach((item) => {
        item?.Colour &&
          item['Colour'].forEach((colorItem) => {
            tempItemColors.push(colorItem?.colour_code);
          });
      });
    return tempItemColors;
  }

  return oosStatus === 0 || data?.available ? (
    <AdvCardContainer available={data?.available}>
      <AdvCardBody
        onClick={() => {
          props.onItemClick ? props.onItemClick(data) : onItemClick(data);
        }}
      >
        <Link href={getLink(data)}>
          <AdvImageWrapper>
            <AdvImage
              aspectRatio={theme?.theme?.product_aspect_ratio}
              src={
                data?.images?.length
                  ? getImageUrlFromCompressedImages(
                      data?.images?.[0]?.compressed_images,
                      data?.images?.[0]?.thumbnail_url || DEFAULT_IMAGE_URL,
                      {
                        maxImgSize: imgSizeEnums.MID,
                      }
                    )
                  : DEFAULT_IMAGE_URL
              }
            />
            {data?.images?.length > 1 ? (
              <AdvImageHover
                aspectRatio={theme?.theme?.product_aspect_ratio}
                src={getImageUrlFromCompressedImages(
                  data?.images?.[1]?.compressed_images,
                  data?.images?.[1]?.thumbnail_url,
                  {
                    maxImgSize: imgSizeEnums.MID,
                  }
                )}
              />
            ) : null}
          </AdvImageWrapper>
        </Link>
        <AdvCardDetailContainer>
          <AdvCardDetailLeftSection>
            <AdvCardTitle>{data?.name || 'Available Items'}</AdvCardTitle>
            {!!data?.available && checkForLimitedQuantity(data) && (
              <AdvCardInventory className="highlighter-red-color">
                Limited Stock Left
              </AdvCardInventory>
            )}
            <AdvCardPrice>
              {isB2bStore ? (
                <AdvAmountDiscounted fontColor={theme?.colors?.primary_color}>
                  {data?.b2b_pricing_info?.price_range || 'Price on Request'}
                </AdvAmountDiscounted>
              ) : (
                <>
                  <AdvAmountDiscounted fontColor={theme?.colors?.primary_color}>
                    {CURRENCY_SYMBOL}
                    {data?.discounted_price}
                  </AdvAmountDiscounted>
                  {data?.discounted_price !== data?.price && (
                    <AdvAmountActual>₹{data?.price}</AdvAmountActual>
                  )}
                  {discountPercent > 0 && (
                    <AdvCardDiscount>{`${discountPercent}% OFF`}</AdvCardDiscount>
                  )}
                </>
              )}
            </AdvCardPrice>
            <OfferAvailableTag productInfo={data} customClasses="tw-mt-[8px]" />
            {!noTags && (
              <AdvTagsContainer>
                {data?.tags.map((item) => {
                  return (
                    <AdvTagsItem
                      key={`tags-${item.name}-${item?.id}`}
                      color={item?.tag_color}
                    >
                      {item.name}
                    </AdvTagsItem>
                  );
                })}
              </AdvTagsContainer>
            )}
            {!!itemColors?.length && (
              <AdvCardColorComponent>
                {itemColors.map((colorCode, index) => {
                  return (
                    <div
                      key={`card-color-${colorCode}-${index}`}
                      onClick={(e) => {
                        onItemClick({ ...data, color: colorCode }, e);
                      }}
                    >
                      <ColorVariant
                        isFirst={index === 0 ? true : false}
                        color={colorCode}
                      />
                    </div>
                  );
                })}
              </AdvCardColorComponent>
            )}
            {!data?.available && !isB2bStore && (
              <AdvCardDescription>Out of stock</AdvCardDescription>
            )}
          </AdvCardDetailLeftSection>
          <AdvCardDetailRightSection>
            {data?.store_item_review_rating?.review_rating_exist && (
              <DisplayRating storeItemReviewRating={data?.store_item_review_rating} />
            )}
          </AdvCardDetailRightSection>
        </AdvCardDetailContainer>
      </AdvCardBody>
    </AdvCardContainer>
  ) : null;
}

export default AdvanceCard;
