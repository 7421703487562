import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../../../../utils/constants';

export const SortAndFilterItemsContainer = styled.div`
 > section {
  margin-top : 24px;
  display: grid;
  justify-self: center;
  justify-items: center;
  grid-auto-rows: auto;

  @media screen and (min-width: ${MAX_MOBILE_WIDTH}px) {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
  margin-left : ${props => (props?.showFilterDrawer ? '76px' : '0px')};
  justify-self: baseline;
  justify-items: baseline;
  }
   @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
      margin-left: 0px;
      column-gap: 20px;
      row-gap: 15px;
       justify-self: center;
  justify-items: center;
      ${props => (props?.showSortByDropDown ? 'opacity : 0.2' : 'opacity : 1')};
    }
`;

export const ItemsNotFoundSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ItemsNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  postion: relative;
  margin-top: 2rem;
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}px) {
    width: 60vw;
  }
`;
