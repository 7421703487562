import React, { useState, useEffect, useRef } from 'react';
import { deviceWidth } from '@/utils/deviceWidth';
import { MAX_MOBILE_WIDTH, SORTING_TYPE } from '@/utils/constants';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import FlatList from '@/components/FlatList';
// import LoaderIcon from '@/components/Common/LoaderIcon';
import {
  getFilteredItemsOptions,
  resetFilterItems,
  saveFilteredItems,
  saveFilteredOptions,
  setSortType,
} from '@/redux/actions';
import { getFilteredOptionsProducts } from '@/utils/getFilteredOptionProducts';
import { ItemsNotFound } from '@/assets/svgExports/ItemsNotFound';
import AdvanceCard from '../../../../AdvanceCard/AdvanceCard';
import {
  ItemsNotFoundContainer,
  ItemsNotFoundSvgContainer,
  SortAndFilterItemsContainer,
} from './SortAndFilteredItems.styles';
import {
  scrollIfItemPreviouslyClicked,
  getStoredMB7SortAndFilterConfig,
  setMB7SortAndFilterConfig,
} from '../../utils';
import { useItemClick } from '@/hooks/useItemClick';
import { useSSRSelector } from '@/redux/ssrStore';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { GlobalCfeLoader } from '@/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';

const middleBodyScrollCorrectionOffset = 80;
const TYPE_TAG = 3;

function SortAndFilteredItems(props) {
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const dispatch = useDispatch();
  const router = useRouter();
  const isMobile = deviceWidth <= MAX_MOBILE_WIDTH;
  const [onItemClick] = useItemClick();
  const [initialFilter, setInitialFilter] = useState([]);
  const {
    filteredItems,
    filteredOptions,
    filterAppliedCounter,
    sortType,
    filterOptionLoader,
  } = useSelector((state) => ({
    filteredItems: state.catalogReducer.filteredItems,
    filteredOptions: state.catalogReducer.filteredOptions,
    filterAppliedCounter: state.catalogReducer.filterAppliedCounter,
    sortType: state.catalogReducer.sortType,
    filterOptionLoader: state.commonReducer.filterOptionLoader,
  }));

  const { categories, collections, storeData, productTags } = useSSRSelector((state) => ({
    categories: state.catalogReducer?.categories,
    collections: state.catalogReducer?.collections,
    storeData: state.storeReducer.store,
    productTags: state.catalogReducer?.productTags,
  }));

  const loader = useRef(false);
  const initialPlpCall = useRef();
  const initialFilterSortTypeChangeCall = useRef(false);
  const mb7SortAndFilterConfig = getStoredMB7SortAndFilterConfig();

  /**
   * If item is clicked and user navigates back to PLP, restore scroll to that particular item
   */
  useEffect(() => {
    scrollIfItemPreviouslyClicked();
  }, []);

  /**
   * If there is no change in filter from the one previously stored, then return. This prevents unnecessary API calls when component first mounts and each useeffect is called once.
   */

  useEffect(() => {
    if (
      mb7SortAndFilterConfig?.allowReset &&
      mb7SortAndFilterConfig?.filterAppliedCounterChanged
    ) {
      handleFilterCounterAndSortTypeChange();
    }
  }, [filterAppliedCounter]);

  useEffect(() => {
    if (mb7SortAndFilterConfig?.allowReset && mb7SortAndFilterConfig?.sortTypeChanged) {
      handleFilterCounterAndSortTypeChange();
    }
  }, [sortType]);

  useEffect(() => {
    if (mb7SortAndFilterConfig?.allowReset) {
      fetchDataOnCollectionCategoryChange();
    } else {
      setMB7SortAndFilterConfig({ allowReset: true });
      dispatch(saveFilteredItems(mb7SortAndFilterConfig?.filteredItems || []));
      dispatch(saveFilteredOptions(mb7SortAndFilterConfig?.filteredOptions || []));
      dispatch(setSortType(mb7SortAndFilterConfig?.sortType || SORTING_TYPE.CATEGORIES));
    }
  }, [router?.query?.cid, router?.query?.collectionId]);

  function fetchDataOnCollectionCategoryChange() {
    const { cid, collectionId } = router?.query;
    if (cid !== undefined || collectionId !== undefined) {
      initialPlpCall.current = false;
      dispatch(setSortType(SORTING_TYPE.CATEGORIES));
      dispatch(resetFilterItems());
      setMB7SortAndFilterConfig({
        cid,
        collectionId,
        sortType: SORTING_TYPE.CATEGORIES,
        filterAppliedCounter: 0,
        isNextPage: true,
      });
      plpInitFilterCall();
    }
  }

  function plpInitFilterCall() {
    if (router?.query?.cid) {
      const selectedCategory = categories.find(
        (categoryItem) => categoryItem?.id == router?.query?.cid
      );
      if (selectedCategory) {
        const categoryFilterObject = {};
        const categorySubFilter = [];
        const subFilter = {};
        subFilter['id'] = selectedCategory?.id;
        subFilter['name'] = selectedCategory?.name;
        categorySubFilter.push(subFilter);
        categoryFilterObject['sub_filters'] = categorySubFilter;
        categoryFilterObject['name'] = 'Categories';
        categoryFilterObject['type'] = 2;
        categoryFilterObject['is_selected'] = true;

        const filterArr = [categoryFilterObject];
        if (router?.query?.collectionId) {
          const collectionFilterObject = createCollectionFilterObject();
          filterArr.push(collectionFilterObject);
        }

        dispatch(saveFilteredOptions(filterArr));
        setInitialFilter(filterArr);
        plpFilterCall(filterArr, SORTING_TYPE.CATEGORIES);
      }
      const selectedTag = productTags.find(
        (tagItem) => tagItem?.id == router?.query?.cid
      );
      if (selectedTag) {
        const tagFilterObject = {};
        const tagSubFilter = [];
        const subFilter = {};
        subFilter['id'] = selectedTag?.id;
        subFilter['name'] = selectedTag?.name;
        tagSubFilter.push(subFilter);
        tagFilterObject['sub_filters'] = tagSubFilter;
        tagFilterObject['name'] = 'Tags';
        tagFilterObject['type'] = 3;
        tagFilterObject['is_selected'] = true;
        dispatch(saveFilteredOptions([tagFilterObject]));
        setInitialFilter([tagFilterObject]);
        plpFilterCall([tagFilterObject], SORTING_TYPE.CATEGORIES);
      }
    } else if (router?.query?.collectionId) {
      const collectionFilterObject = createCollectionFilterObject();
      dispatch(saveFilteredOptions([collectionFilterObject]));
      setInitialFilter([collectionFilterObject]);
      plpFilterCall([collectionFilterObject], SORTING_TYPE.CATEGORIES);
    }
  }

  function createCollectionFilterObject() {
    const collectionFilterObject = {};
    const collectionSubFilter = [];
    const subFilter = {};
    const selectedCollectionItem = collections.find(
      (collectionItem) => collectionItem.id == router?.query?.collectionId
    );
    subFilter['id'] = selectedCollectionItem?.id;
    subFilter['name'] = selectedCollectionItem?.name;
    collectionSubFilter.push(subFilter);
    collectionFilterObject['sub_filters'] = collectionSubFilter;
    collectionFilterObject['name'] = 'Collections';
    collectionFilterObject['type'] = 1;
    collectionFilterObject['colour_code'] = '';
    collectionFilterObject['is_selected'] = true;

    return collectionFilterObject;
  }

  function changeinitialPlpCallToFalse(status, isNextPage) {
    if (status) {
      initialPlpCall.current = false;
      setMB7SortAndFilterConfig({ isNextPage: isNextPage, pageNo: 1 });
    }
  }

  function plpFilterCall(filterObject, sortType) {
    const payload = {
      store_id: storeData?.store_id,
      page_no: 1,
      sorting_type: sortType,
      filters: filterObject,
    };
    initialPlpCall.current = true;
    dispatch(getFilteredItemsOptions(payload, changeinitialPlpCallToFalse));
  }

  /**
   * creates Payload of filters for sortAndFilterApi.
   * @returns {array} of selected filters.
   */

  function getSelectedFilters() {
    /** add previous filteroption of collection and category and PLP page */
    let tempFiltersArray =
      router?.query?.cid !== undefined || router?.query?.collectionId !== undefined
        ? [...initialFilter]
        : [];
    if (initialFilter?.[0]?.type === TYPE_TAG && router?.query?.cid) {
      tempFiltersArray = [];
    }
    !!filteredOptions?.length &&
      filteredOptions.forEach((filterItem) => {
        const tempFilterObject = {};
        const tempSubFilterArray = [];
        !!filterItem.sub_filters?.length &&
          filterItem.sub_filters.forEach((subFilterItem) => {
            const tempSubFilters = {};
            if (subFilterItem?.is_selected) {
              tempSubFilters['id'] = subFilterItem?.id;
              tempSubFilters['name'] = subFilterItem?.name;
              tempSubFilters['colour_code'] = subFilterItem?.colour_code;
              tempSubFilterArray.push(tempSubFilters);
            }
          });
        if (tempSubFilterArray?.length) {
          tempFilterObject['name'] = filterItem?.name;
          tempFilterObject['type'] = filterItem?.type;
          tempFilterObject['sub_filters'] = tempSubFilterArray;
          tempFiltersArray.push(tempFilterObject);
        }
      });
    return tempFiltersArray;
  }

  async function loadMoreItems() {
    if (!mb7SortAndFilterConfig?.isNextPage) {
      return;
    }
    const nextPageNumber = (mb7SortAndFilterConfig?.pageNo || 0) + 1;
    if (
      !loader.current &&
      !initialPlpCall.current &&
      !initialFilterSortTypeChangeCall.current
    ) {
      loader.current = true;
      const { status, is_next_page } = await getFilteredOptionsProducts(
        getSelectedFilters(),
        nextPageNumber,
        { isMiddleBody7: true }
      );
      if (status) {
        setMB7SortAndFilterConfig({ isNextPage: is_next_page, pageNo: nextPageNumber });
      }
      loader.current = false;
    }
  }

  function removeDuplicateObjects(array) {
    array = Array.isArray(array) ? array : [];
    const uniqueObjects = [];
    const seenIds = new Set();
    for (const obj of array) {
      const objectId = obj.id;
      if (!seenIds.has(objectId)) {
        seenIds.add(objectId);
        uniqueObjects.push(obj);
      }
    }
    return uniqueObjects;
  }

  function returnFilterItemsData() {
    let items = [];
    if (!mb7SortAndFilterConfig.allowReset) {
      items = mb7SortAndFilterConfig?.filteredItems;
      dispatch(saveFilteredItems(mb7SortAndFilterConfig?.filteredItems || []));
      dispatch(saveFilteredOptions(mb7SortAndFilterConfig?.filteredOptions || []));
    } else {
      items = filteredItems;
    }
    return removeDuplicateObjects(items);
  }

  function handleFilterCounterAndSortTypeChange(data) {
    dispatch(resetFilterItems());
    loader.current = false;
    initialFilterSortTypeChangeCall.current = true;
    !isMobile &&
      window.scrollTo({
        top:
          props?.middleBodyContainerRef?.current.getBoundingClientRect()?.top +
          window.scrollY -
          middleBodyScrollCorrectionOffset,
        left: 0,
        behavior: 'instant',
      });
    const payload = {
      store_id: storeData?.store_id,
      page_no: 1,
      sorting_type: sortType,
      filters: getSelectedFilters(),
    };
    dispatch(
      getFilteredItemsOptions(payload, (status, isNextPage) => {
        if (status) {
          initialFilterSortTypeChangeCall.current = false;
          setMB7SortAndFilterConfig({
            isNextPage: isNextPage,
            pageNo: 1,
            filterAppliedCounterChanged: false,
            sortTypeChanged: false,
          });
        }
      })
    );
  }

  const handleCardClick = (data) => {
    setMB7SortAndFilterConfig(
      {
        filteredItems,
        filteredOptions,
        filterAppliedCounter,
        allowReset: false,
        sortType,
      },
      true
    );
    onItemClick(data);
  };

  return (
    <SortAndFilterItemsContainer
      showFilterDrawer={props?.showFilterDrawer}
      showSortByDropDown={props?.showSortByDropDown}
    >
      <FlatList
        isLoaderVisible={false}
        isNext={true}
        onScrollEnd={() => loadMoreItems()}
        // add LoaderComponent for loading sections
        renderList={() => {
          return returnFilterItemsData()?.map((data) => {
            return (
              <AdvanceCard
                key={`${data?.id}-Filtered-items-`}
                data={data}
                showButton={true}
                noTags={!data?.tags?.length}
                onItemClick={handleCardClick}
              />
            );
          });
        }}
      />
      {loader.current && !!mb7SortAndFilterConfig?.isNextPage && (
        <div className="flex justify-center mt4 relative">
          <GlobalCfeLoader
            type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
            color={globalStyle?.appLoader?.color}
            style={{ transform: 'translateX(-50%)' }}
          />
          {/* <LoaderIcon style={{ transform: 'translateX(-50%)' }} /> */}
        </div>
      )}
      {!filteredItems?.length &&
        !!mb7SortAndFilterConfig?.isNextPage &&
        !filterOptionLoader && (
          <ItemsNotFoundContainer>
            <ItemsNotFoundSvgContainer>
              <ItemsNotFound />
            </ItemsNotFoundSvgContainer>
            <span className="flex w-100 justify-center">No Results Found</span>
          </ItemsNotFoundContainer>
        )}
    </SortAndFilterItemsContainer>
  );
}

export default SortAndFilteredItems;
