import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import styled from 'styled-components';

export const RatingContainer = styled.div`
  display: flex;
  height: 26px;
  background: rgba(0, 0, 0, 0.05);
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    width: 59px;
    margin-top: 10px;
    height: 21px;
  }
`;

export const RatingText = styled.div`
  display: flex;
  font-size: 12px;
  color: black;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 10px;
  }
`;
