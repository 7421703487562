import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ColorCircle, DisabledColorCircle } from '@/assets/svgExports/ColorCircle';
import { ColorEllipse } from '@/assets/svgExports/ColorEllipse';
import { ColoredSquare, DisabledSquare } from '@/assets/svgExports/ColoredSquare';
import {
  ColoredEllipseElementContainer,
  ColoredRectangleElementContainer,
  ColoredCircleElementContainer,
} from './ColorVariant.styles';

const BlackTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#000', // Black background
    color: '#fff', // White text color
    fontSize: 12, // Adjust font size if needed
    padding: '6px 8px',
    fontFamily: 'montserrat',
    fontWeight: 500,
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#000', // Black arrow color
  },
}));

/**
 * This Component is UI for showing products Color of Variant option throughout code base
 * @param {*} props
 * @return {JSX.Element} Button UI component
 */
function ColorVariant(props) {
  const {
    color,
    variantName,
    activeVariantOption,
    isSelectedOption,
    rowIndex,
    returnIsVariantVisible,
    returnIsVariantAvailable,
    outOfVisbleInfo,
    outOfStockInfo,
    toggleClick,
    handleVariantBtnClick,
    isFirst,
    isPdp = false,
  } = props;

  /** checks if the UI Button is in Selected state */
  const [isSelected, setIsSelected] = useState(false);

  /** Checks if a Color is hidden or not in current variant configuration*/
  const [isVisible, setIsVisible] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (isPdp) {
      if (variantName === activeVariantOption) {
        setIsSelected(true);
      } else setIsSelected(false);
    }
  }, [isSelectedOption, activeVariantOption, toggleClick]);

  useEffect(() => {
    if (isPdp) {
      setIsVisible(returnIsVariantVisible(variantName, rowIndex));
      setIsAvailable(returnIsVariantAvailable(variantName, rowIndex));
    }
  }, [variantName, rowIndex, outOfVisbleInfo, toggleClick, outOfStockInfo]);

  /** renders Colored Squares  */
  function renderColoredRectancle() {
    return (
      <ColoredRectangleElementContainer
        onClick={() => {
          handleVariantBtnClick(rowIndex, variantName, isVisible);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!isAvailable ? (
          <DisabledSquare color={color} borderColor={isSelected ? '#999999' : '#fff'} />
        ) : isSelected ? (
          <ColoredSquare color={color} borderColor={props?.themeColor} />
        ) : (
          <ColoredSquare
            color={color}
            borderColor={isHovered ? props?.storeTheme?.colors?.primary_color : null}
          />
        )}
      </ColoredRectangleElementContainer>
    );
  }

  /** returns a colored ellipses */
  function renderColoredEllipse() {
    return (
      <ColoredEllipseElementContainer>
        <ColorEllipse isFirst={isFirst ? isFirst : false} color={color} />
      </ColoredEllipseElementContainer>
    );
  }

  function renderColoredCircle() {
    return (
      <ColoredCircleElementContainer
        onClick={() => {
          handleVariantBtnClick(rowIndex, variantName, isVisible);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!isAvailable ? (
          <DisabledColorCircle
            color={color}
            borderColor={isSelected ? '#999999' : '#fff'}
          />
        ) : isSelected ? (
          <ColorCircle
            color={color}
            borderColor={props?.storeTheme?.colors?.primary_color}
          />
        ) : (
          <ColorCircle
            color={color}
            borderColor={isHovered ? props?.storeTheme?.colors?.primary_color : null}
          />
        )}
      </ColoredCircleElementContainer>
    );
  }

  function renderPdpColorVariant() {
    if (!isVisible) {
      return null;
    }

    return (
      <BlackTooltip
        title={<span className="tw-text-[12px] tw-font-light">{variantName}</span>}
        placement="top"
        arrow
      >
        {props?.storeTheme?.roundness ? renderColoredCircle() : renderColoredRectancle()}
      </BlackTooltip>
    );
  }

  return isPdp ? renderPdpColorVariant() : renderColoredEllipse();
}

export default ColorVariant;
