const WHITE_COLORS = ['white', '#fff', '#ffffff'];
export function ColorCircle({ color, borderColor = '#fff' }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="34.5"
        height="34.5"
        rx="17.25"
        stroke={borderColor}
        stroke-width="1.5"
      />
      <rect
        x="4"
        y="4"
        width="28"
        height="28"
        rx="14"
        fill={color}
        stroke={WHITE_COLORS.includes(color?.toLowerCase()) ? '#e2e2e2' : '#fff'}
      />
    </svg>
  );
}

export function DisabledColorCircle({ color, borderColor = '#fff' }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="34.5"
        height="34.5"
        rx="17.25"
        stroke={borderColor}
        stroke-width="1.5"
      />
      <g clip-path="url(#clip0_138_1543)">
        <rect
          x="4"
          y="4"
          width="28"
          height="28"
          rx="14"
          fill={color}
          fill-opacity="0.15"
        />
        <rect
          x="4.5"
          y="4.5"
          width="27"
          height="27"
          rx="13.5"
          stroke="black"
          stroke-opacity="0.1"
        />
        <line x1="8.3625" y1="8.65563" x2="27.3625" y2="28.6556" stroke="#BDBDBD" />
      </g>
      <defs>
        <clipPath id="clip0_138_1543">
          <rect width="28" height="28" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
