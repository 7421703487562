export function ColoredSquare({ color, borderColor }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 0.5H35.5V35.5H0.5V0.5Z" stroke={borderColor || '#fff'} />
      <rect x="4" y="4" width="28" height="28" fill={color} />
      <rect x="3.5" y="3.5" width="29" height="29" stroke="black" stroke-opacity="0.1" />
    </svg>
  );
}

export function DisabledSquare({ color, borderColor = '#fff' }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="34.5"
        height="34.5"
        stroke={borderColor}
        stroke-width="1.5"
      />
      <g clip-path="url(#clip0_138_1456)">
        <rect x="4" y="4" width="28" height="28" fill={color} fill-opacity="0.15" />
        <rect
          x="4.5"
          y="4.5"
          width="27"
          height="27"
          stroke="black"
          stroke-opacity="0.1"
        />
        <line x1="2.35355" y1="2.64645" x2="32.3536" y2="32.6465" stroke="#BDBDBD" />
      </g>
      <defs>
        <clipPath id="clip0_138_1456">
          <rect width="28" height="28" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
