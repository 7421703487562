import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../utils/constants';

export const AdvCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px){
      max-width: 45vw;
  }
`;

export const AdvCardBody = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const AdvCardDetailContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    flex-direction: column;
  }
`;

export const AdvCardDetailLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

export const AdvCardDetailRightSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdvCardTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

export const AdvCardInventory = styled.p`
  font-weight: 400;
  font-size: 10px;
  margin: 0 0 12px 0;
`;

export const AdvCardPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
`;

export const AdvAmountDiscounted = styled.span`
  display: flex;
  align-items: center;
  color: ${(props) => props.fontColor};
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 700;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 13px;
  }
`;

export const AdvAmountActual = styled.span`
  display: inline-block;
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.3);
  letter-spacing: 1.3px;
  margin-left: 10px;
  font-size: 13px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 10px;
  }
`;

export const AdvCardDiscount = styled.div`
  display: flex;
  color: #3ea200;
  font-size: 11px;
  text-transform: uppercase;
  z-index: 2;
  padding: 2px 3px;
  margin-left: 8px;
  ${(props) => (props.imageUrl ? `bottom: 0px;` : `top: -25px;`)}
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 10px;
  }
`;

export const AdvCardDescription = styled.p`
  margin: 0;
  color: #9c9c9c;
  font-weight: 400;
  letter-spacing: 0.26px;
  line-height: 1.5;
  margin-top: 8px;
  font-size: 14px;

  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

export const AdvImageWrapper = styled.div`
  position: relative;
`;

export const AdvImageHover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-out;
  ${(props) =>
    props.aspectRatio ? `aspect-ratio: ${props.aspectRatio / 100}` : `aspect-ratio: 1`};
  &:hover {
    opacity: 1;
    background: #fff;
  }
`;

export const AdvImage = styled.img`
  ${(props) =>
    props.aspectRatio ? `aspect-ratio: ${props.aspectRatio / 100}` : `aspect-ratio: 1`};
`;

export const AdvCardColorComponent = styled.div`
  display: flex;
  margin-top: 12px;
  position: relative;
  z-index: 2;
`;

export const AdvTagsContainer = styled.div`
  display: block;
  flex-direction: row;
  width: max-content;
  > span:first-child {
    margin-left: 0px;
  }
   @media screen and (max-width: ${MAX_MOBILE_WIDTH}px){
     width: auto;
  }
`;

export const AdvTagsItem = styled.span`
  margin-top: 10px;
  font-size: 11px;
  margin-left: 5px;
  font-weight: 400;
  height: 22px;
  background: ${(props) => props?.color && props.color};
  padding: 4px;
  color: #ffffff;
  display: inline-block;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
