export function ItemsNotFound() {
  return (
    <svg
      width="250"
      height="200"
      viewBox="0 0 250 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="250" height="200" fill="white" />
      <path
        d="M120.5 133C139.002 133 154 118.002 154 99.5C154 80.9985 139.002 66 120.5 66C101.998 66 87 80.9985 87 99.5C87 118.002 101.998 133 120.5 133Z"
        fill="#F2F2F2"
        stroke="#828282"
        strokeWidth="2.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.132 125.494C116.891 125.819 118.68 125.987 120.5 126C135.136 126 147 114.136 147 99.5C147 84.8645 135.136 73 120.5 73C116.74 73 113.164 73.7829 109.924 75.1946C104.294 77.6479 99.6816 81.9999 96.896 87.4419C95.0445 91.0589 94 95.1575 94 99.5C94 103.44 94.8599 107.179 96.4021 110.54C97.5032 112.94 98.9521 115.146 100.684 117.096"
        fill="white"
      />
      <path
        d="M115.132 125.494C116.891 125.819 118.68 125.987 120.5 126C135.136 126 147 114.136 147 99.5C147 84.8645 135.136 73 120.5 73C116.74 73 113.164 73.7829 109.924 75.1946C104.294 77.6479 99.6816 81.9999 96.896 87.4419C95.0445 91.0589 94 95.1575 94 99.5C94 103.44 94.8599 107.179 96.4021 110.54C97.5032 112.94 98.9521 115.146 100.684 117.096"
        stroke="#828282"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M103.797 120.074C105.945 121.82 108.372 123.237 111 124.246"
        stroke="#828282"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path d="M148 126L154 132" stroke="#828282" strokeWidth="2.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.029 131.031C151.136 132.924 151.136 135.992 153.029 137.885L164.114 148.97C166.007 150.863 169.076 150.863 170.968 148.97C172.861 147.078 172.861 144.009 170.968 142.116L159.883 131.031C157.99 129.138 154.922 129.138 153.029 131.031Z"
        fill="#F2F2F2"
        stroke="#828282"
        strokeWidth="2.5"
      />
      <path d="M158 133L169 144" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.001 88.0005C114.001 99.5985 123.403 109.001 135.001 109.001C137.279 109.001 139.472 108.638 141.527 107.967C138.174 116.288 130.023 122.161 120.501 122.161C107.985 122.161 97.8398 112.016 97.8398 99.5005C97.8398 88.1601 106.17 78.7653 117.046 77.1016C115.113 80.2798 114.001 84.0102 114.001 88.0005Z"
        fill="#F2F2F2"
      />
      <path
        d="M121 81C119.727 81 118.482 81.1253 117.279 81.3642M113.645 82.4761C106.804 85.3508 102 92.1144 102 100"
        stroke="#E0E0E0"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M174.176 99.7773H166M180.5 92H163.324H180.5ZM187.5 92H185.279H187.5Z"
        stroke="#E0E0E0"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.1743 121.777H75.9985M79.4985 113H62.3228H79.4985ZM56.4985 113H52.2773H56.4985Z"
        stroke="#E0E0E0"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
